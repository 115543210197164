<template>
    <div class="container-xxl flex-grow-1 container-p-y app-kanban" v-if="!isLoading">

        <div class="row">
            <div class="col-12 col-md-12 mb-4">
                <div class="card">
                    <div class="card-header d-flex justify-content-between flex-wrap mb-0 pb-1 ">
                        <div>
                            <small>PROJEKT</small>
                            <h5 style="min-height: 20px;">
                                {{ project.project_shortname }}
                            </h5>
                        </div>
                        <div>
                            <router-link :to="'/project/' + id + '/edit'" class="btn btn-outline-primary me-2 f-cap">
                                Edytuj projekt
                            </router-link>
                        </div>
                    </div>

                </div>
            </div>

            <ProjectNavComponent :project_id="id" value="file"></ProjectNavComponent>

            <FileChooserInput :folder="'project/' + project.project_id" v-model="uploadFileName" hidefolders="1"
                hide_input="1" title="Zarządzaj plikami" style="float:left" @uploadSuccess="uploadSuccess" onlymanager="1">
            </FileChooserInput>
            <div class="col-12 col-md-12 filter-bar  mb-5">

                <div class="card mb-5">
                    <div class="card-body">
                        <div class="row">


                            <div v-for="file, index in files" :key="index"
                                class=" col-12 col-lg-6 d-flex flex-wrap justify-content-between mb-2"
                                style="max-width:100%">
                                <span style="font-size: 30px; padding-top:10px; padding-right:2px; padding-bottom:2px"
                                    class="material-icons-outlined">
                                    {{ isImage(file.path) ? 'image' : 'description' }}
                                </span>
                                <div class="col ps-11 pe-12" style="max-width:60%">
                                    <a v-if="isImage(file.path)" class="text-secondary" data-fslightbox="1" :href="BASE_URL + file.path">
                                        <span class="text-dark"> {{ file.path.split('/').at(-1) }}</span><br>
                                        <small> Rozmiar: {{ file.file_size / 1000 }}KB </small>
                                    </a>
                                    <a v-else  target="_blank" class="text-secondary" :href="BASE_URL + file.path">
                                        <span class="text-dark"> {{ file.path.split('/').at(-1) }}</span><br>
                                        <small> Rozmiar: {{ file.file_size / 1000 }}KB </small>
                                    </a>
                                </div>
                                <div class="col-xs-12 text-end col text-right">

                                    <a target="_blank" :href="BASE_URL + file.path"
                                        class="btn btn-outline-secondary btn-icon-sm"><span class="material-icons-outlined">
                                            download
                                        </span>
                                    </a>

                                    <!-- <delete_confirm :url="file['_links'].delete.href" @confirm="load()"
                                    v-if="showdelete && file['_links'].delete" text="Czy napewno chcesz usunąć plik?">
                                </delete_confirm> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card" v-if="snapshot.length > 0">
                    <div class="card-body">
                        <div class="row">


                            <div v-for="file, index in snapshot" :key="index"
                                class=" col-12 col-lg-6 d-flex flex-wrap justify-content-between mb-2"
                                style="max-width:100%">

                                <div class="flex-fill ps-11 pe-12">
                                    <a data-fslightbox="1" :href="BASE_URL + file.path"><img :src="BASE_URL + file.path"
                                            class="mw-100">
                                    </a> {{ file.path.split('/').at(-1) }}<br>
                                </div>
                                <div class="text-end col text-right">

                                    <!-- <delete_confirm :url="file['_links'].delete.href" @confirm="load()"
                                    v-if="showdelete && file['_links'].delete" text="Czy napewno chcesz usunąć plik?">
                                </delete_confirm> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>

import api from '../../api/api';
import FileChooserInput from '../filemanager/FileChooserInput.vue';
import ProjectNavComponent from './components/ProjectNavComponent.vue';

export default {
    name: 'ProjectFile',
    components: {
        FileChooserInput,
        ProjectNavComponent
    },
    data: function () {
        return {
            project: {},
            files: [],
            BASE_URL: api.getBaseUrl() + '/files/',
            uploadFileName: '',
            isLoading: true,
            snapshot: [],

        }
    },
    computed: {},
    methods: {
        isImage: function (name) {
            return ['png', 'jpg', 'jpeg', 'gif'].includes(name.split('.').at(-1))
        },
        uploadSuccess: function () {
            this.getProjectFile();
        },
        getProject: function () {
            this.isLoading = true;

            api.getProject(this.id).then(response => {
                this.project = response.data
                this.isLoading = false;
            });
        },
        getProjectFile: function () {
            api.getProjectFile(this.id).then(response => {
                this.files = response.data; setTimeout(function () {
                    window.refreshFsLightbox()
                }, 500)
            });
            api.getProjectSnapshot(this.id).then(response => {
                this.snapshot = response.data;
                setTimeout(function () {
                    window.refreshFsLightbox()
                }, 500)
            });

        },
    },
    created() {
        this.id = this.$route.params.id;
        this.getProject();
        this.getProjectFile();
    }
}
</script>
<style></style>