<template>
    <div class="col-12">
        <div class="w-100 bg-light rounded-3 mb-4 p-3 shadow-sm">
            <ul class="nav nav-pills">
                <li class="nav-item">
                    <router-link :to="'/project/' + project_id + '/summary'" :class="{ 'active': value == 'summary' }" class="nav-link "
                        aria-current="page" href="#">Oś
                        czasu</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="'/project/' + project_id + '/task'" :class="{ 'active': value == 'task' }" class="nav-link"
                        href="#">Tablica
                        Kanban</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="'/board/' + project_id" :class="{ 'active': value == 'board' }" class="nav-link"
                        href="#">Artboard</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="'/project/' + project_id + '/file'" :class="{ 'active': value == 'file' }" class="nav-link "
                        href="#">
                        Pliki</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="'/project/' + project_id + '/calendar'" :class="{ 'active': value == 'calendar' }"
                        class="nav-link " href="#">
                        Kalendarz</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ProjectNavComponent',
    props: ['value','project_id']
}
</script>