<template>
    <div class="form-group w-100">
        <div class="input-group">
            <input class="form-control" type="text" v-model="file_path" v-show="!hide_input">
            <div class="input-group-append">
                <button type="button" @click.prevent="modal = true" class="btn btn-primary me-2 mb-4">
                    {{ title ? title : 'Wybierz' }}
                </button>
            </div>
        </div>
        <MagicoModal class_other="modal-xl" v-model="modal">
            <FilemanagerIndex @uploadSuccess="$emit('uploadSuccess')" v-if="modal" :folder="folder_base" :hidefolders="hidefolders" @choose-file="choosed"
                :choose="onlymanager ? false : true">
            </FilemanagerIndex>
        </MagicoModal>
    </div>
</template>
<script>
import MagicoModal from '../../components/MagicoModal.vue';
import FilemanagerIndex from './FilemanagerIndex.vue';
import api from '../../api/api';

export default {
    name: 'FileChooserInput',
    props: ['value', 'hide_input', 'title', 'folder', 'hidefolders', 'onlymanager'],
    components: {
        MagicoModal,
        FilemanagerIndex
    },
    data: function () {
        return {
            file_path: '',
            folder_base: '',
            modal: false,
            baseUrl: api.getBaseUrl()
        }
    },
    methods: {
        choosed: function (ev) {
            this.file_path = this.baseUrl + '/files/' + ev.path;
            this.modal = false;
            this.$emit('input', this.file_path);
            this.$emit('change', this.file_path);
        }
    },
    watch: {
        value: function () {
            this.file_path = this.value;
        }
    },
    created: function () {
        this.file_path = this.value;
        this.folder_base = this.folder;
    }
}
</script>